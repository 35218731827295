import React, { useState, useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllProductsShop, deleteProduct, updateProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import { Button, Modal, TextField, Box, Input } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { toast } from "react-toastify";

const AllProducts = () => {
  const { products, isLoading, success, error } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    stock: "",
    description: "",
    category: "",
    originalPrice: "",
    images: [], // Update to handle files
  });

  useEffect(() => {
    if (seller._id) {
      dispatch(getAllProductsShop(seller._id));
    }
  }, [dispatch, seller._id]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product updated successfully!");
    }
  }, [error, success]);

  const handleOpen = (product) => {
    if (product && product.id) {
      setSelectedProduct(product);
      setFormData({
        name: product.name,
        price: product.discountPrice,
        stock: product.stock,
        description: product.description,
        category: product.category,
        originalPrice: product.originalPrice,
        images: product.images ? product.images.map(img => img.url) : [], // Set initial images
      });
      setOpen(true);
    } else {
      console.error("Product object does not contain id:", product);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteProduct(id)).then(() => {
      dispatch(getAllProductsShop(seller._id));
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = new FormData();
    updatedFormData.append("name", formData.name);
    updatedFormData.append("description", formData.description);
    updatedFormData.append("category", formData.category);
    updatedFormData.append("originalPrice", formData.originalPrice);
    updatedFormData.append("discountPrice", formData.price);
    updatedFormData.append("stock", formData.stock);
    updatedFormData.append("shopId", seller._id); // Ensure this is set correctly

    formData.images.forEach((image) => {
      if (typeof image === "string") {
        updatedFormData.append("existingImages", image);
      } else {
        updatedFormData.append("images", image);
      }
    });

    try {
      await dispatch(updateProduct(selectedProduct.id, updatedFormData));

      // Close the modal after successful update
      handleClose();

      // Refresh the product list after updating the product
      dispatch(getAllProductsShop(seller._id));

      toast.success("Product updated successfully!"); // Success message
    } catch (error) {
      console.error('Error updating product:', error);
      toast.error("Failed to update product.");
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({
      ...formData,
      images: [...formData.images, ...files] // Append new files to existing images
    });
  };

  const columns = [
    { field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
    { field: "name", headerName: "Name", minWidth: 180, flex: 1.4 },
    { field: "price", headerName: "Price", minWidth: 100, flex: 0.6 },
    { field: "stock", headerName: "Stock", type: "number", minWidth: 80, flex: 0.5 },
    { field: "sold", headerName: "Sold out", type: "number", minWidth: 130, flex: 0.6 },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/product/${params.id}`}>
          <Button>
            <AiOutlineEye size={20} />
          </Button>
        </Link>
      ),
    },
    {
      field: "Edit",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleOpen(params.row)}>
          <AiOutlineEdit size={20} />
        </Button>
      ),
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleDelete(params.id)}>
          <AiOutlineDelete size={20} />
        </Button>
      ),
    },
  ];

  const rows = products.map((item) => ({
    id: item._id,
    name: item.name,
    price: "US$ " + item.discountPrice,
    stock: item.stock,
    sold: item.sold_out,
  }));

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
          <Modal open={open} onClose={handleClose}>
            <Box
              component="form"
              onSubmit={handleEditSubmit}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              <h2>Edit Product</h2>
              <TextField
                fullWidth
                margin="normal"
                label="Name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Price"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Stock"
                value={formData.stock}
                onChange={(e) => setFormData({ ...formData, stock: e.target.value })}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Description"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Category"
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Original Price"
                value={formData.originalPrice}
                onChange={(e) => setFormData({ ...formData, originalPrice: e.target.value })}
              />
              <Input
                type="file"
                multiple
                onChange={handleImageChange}
                inputProps={{ accept: "image/*" }}
              />
              <div className="w-full flex items-center flex-wrap">
                {formData.images &&
                  formData.images.map((i, index) => (
                    <img
                      src={typeof i === "string" ? i : URL.createObjectURL(i)}
                      key={index}
                      alt=""
                      className="h-[120px] w-[120px] object-cover m-2"
                    />
                  ))}
              </div>
              <Button type="submit" color="primary" variant="contained">
                Update
              </Button>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AllProducts;