import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "../../styles/styles";
import { categoriesData, productData } from "../../static/data";
import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { CgMenuGridO } from "react-icons/cg";
import { TiHeartFullOutline } from "react-icons/ti";
import { IoIosCart } from "react-icons/io";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { BiMenuAltLeft } from "react-icons/bi";
// import { CgProfile } from "react-icons/cg";
import DropDown from "./DropDown";
import Navbar from "./Navbar";
import { useSelector, useDispatch } from "react-redux";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import { RxCross1 } from "react-icons/rx";
import { getAllCategories } from "../../redux/actions/category";
import logo from "../../Assests/images/logo.png"; // Adjust the path to your logo file

const Header = ({ activeHeading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.category);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  const handleCategories = (categoryId) => {
    navigate(`/products?category=${categoryId}`);
    setDropDown(false);
  };

  return (
    <>
     <div className={`${styles.section}`}>
  <div className="hidden 800px:h-[50px] 800px:my-[20px] 800px:flex items-center justify-between ">
    <div>
      <Link to="/">
        <img src={logo} alt="Everday Ethiopia Logo" className="h-10" /> {/* Logo instead of text */}
      </Link>
    </div>
    {/* search box */}
    <div className="w-[50%] relative">
    <input
  type="search"
  placeholder="What are you looking for?"
  className="h-[50px] w-full px-2 border-[#1e1e1e] border-[1px] border-opacity-25 rounded-[50px] shadow-md placeholder:text-opacity-50 "
  value={searchTerm}
  onChange={handleSearchChange}
/>
<div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center justify-center bg-[#FAC50C] rounded-full w-8 h-8 cursor-pointer"> {/* Centered circle background */}
    <BiSearch size={20} className="text-[#1e1e1e]" /> {/* Adjust size if needed */}
  </div>
      {searchData && searchData.length !== 0 ? (
        <div className="absolute min-h-[30vh] bg-slate-50 shadow-sm-2 z-[9] p-4">
          {searchData &&
            searchData.map((i, index) => {
              return (
                <Link to={`/product/${i._id}`} key={index}>
                  <div className="w-full flex items-start-py-3">
                    <img
                      src={`${i.images[0]?.url}`}
                      alt=""
                      className="w-[40px] h-[40px] mr-[10px]"
                    />
                    <h1>{i.name}</h1>
                  </div>
                </Link>
              );
            })}
        </div>
      ) : null}
    </div>

    <div className={`${styles.button}`}>
      <Link to={`${isSeller ? "/dashboard" : "/shop-create"}`}>
        <h1 className="text-[#1E1E1E] flex items-center">
          {isSeller ? "Go Dashboard" : "Become Seller"}{" "}
          
        </h1>
      </Link>
    </div>
  </div>
</div>

<div
  className={`${
    active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
  } transition hidden 800px:flex items-center justify-between w-full bg-[#fff] border border-[#1e1e1e] border-opacity-50 h-[70px]`}
>
        <div
          className={`${styles.section} relative ${styles.noramlFlex} justify-between`}
        >
          {/* categories */}
          <div onClick={() => setDropDown(!dropDown)}>
        <div className="relative h-[60px] mt-[10px] w-[270px] hidden 1000px:block">
          <CgMenuGridO size={30} color="#FAC50C"className="absolute top-2 " />
          <div className="flex items-center">
          <button
            className={`h-[100%] w-full flex justify-between items-center pl-10 bg-white font-Lato text-[16px] font-[400]`}
          >
            All Categories
          </button>
          <div className="h-[35px] w-[1px] bg-[#1e1e1e] mx-2 mt-1 opacity-50" />
          </div>

          {/* <IoIosArrowDown
            size={20}
            className="absolute right-2 top-4 cursor-pointer"
            onClick={() => setDropDown(!dropDown)}
          /> */}


          {dropDown ? (
            <DropDown
              categoriesData={categories}
              setDropDown={setDropDown}
              handleCategories={handleCategories}
            />
          ) : null}
        </div>
      </div>
          {/* navitems */}
          <div className={`${styles.noramlFlex}`}>
            <Navbar active={activeHeading} />
          </div>

          <div className="flex">
            <div className={`${styles.noramlFlex}`}>
              <div
                className="relative cursor-pointer mr-[15px]"
                onClick={() => setOpenWishlist(true)}
              >
                <TiHeartFullOutline size={30} color="#FAC50C" />
                <span className="absolute right-0 top-0 rounded-full bg-[#1E1E1E] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div
                className="relative cursor-pointer mr-[15px]"
                onClick={() => setOpenCart(true)}
              >
                <IoIosCart
                  size={30}
                  color="#FAC50C"
                />
                <span className="absolute right-0 top-0 rounded-full bg-[#1E1E1E] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {cart && cart.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer mr-[15px]">
                {isAuthenticated ? (
                  <Link to="/profile">
                    <img
                      src={`https://everydayethiopia-backend.onrender.com${user?.avatar?.url}`}
                      className="w-[35px] h-[35px] rounded-full"
                      alt=""
                    />
                  </Link>
                ) : (
                  <Link to="/login">
                    <CgProfile size={30} color="#FAC50C" />
                  </Link>
                )}
              </div>
            </div>

            {/* cart popup */}
            {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

            {/* wishlist popup */}
            {openWishlist ? (
              <Wishlist setOpenWishlist={setOpenWishlist} />
            ) : null}
          </div>
        </div>
      </div>

      {/* mobile header */}
      <div
        className={`${
          active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
        }
      w-full h-[60px] bg-[#fff] z-50 top-0 left-0 shadow-sm 800px:hidden`}
      >
        <div className="w-full flex items-center justify-between">
          <div>
            <BiMenuAltLeft
              size={40}
              className="ml-4"
              onClick={() => setOpen(true)}
            />
          </div>
          <div>
            <Link to="/">
              {/* <img
                src="https://shopo.quomodothemes.website/assets/images/logo.svg"
                alt=""
                className="mt-3 cursor-pointer"
              /> */}
                 <img src={logo} alt="Everday Ethiopia Logo" className="h-10" /> {/* Logo instead of text */}
            </Link>
          </div>
          <div>
            <div
              className="relative mr-[20px]"
              onClick={() => setOpenCart(true)}
            >
              <AiOutlineShoppingCart size={30} />
              <span class="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                {cart && cart.length}
              </span>
            </div>
          </div>
          {/* cart popup */}
          {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

          {/* wishlist popup */}
          {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
        </div>

        {/* header sidebar */}
        {open && (
          <div
            className={`fixed w-full bg-[#0000005f] z-20 h-full top-0 left-0`}
          >
            <div className="fixed w-[70%] bg-[#fff] h-screen top-0 left-0 z-10 overflow-y-scroll">
              <div className="w-full justify-between flex pr-3">
                <div>
                  <div
                    className="relative mr-[15px]"
                    onClick={() => setOpenWishlist(true) || setOpen(false)}
                  >
                    <AiOutlineHeart size={30} className="mt-5 ml-3" />
                    <span class="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                      {wishlist && wishlist.length}
                    </span>
                  </div>
                </div>
                <RxCross1
                  size={30}
                  className="ml-4 mt-5"
                  onClick={() => setOpen(false)}
                />
              </div>

              <div className="my-8 w-[92%] m-auto h-[40px relative]">
                <input
                  type="search"
                  placeholder="Search Product..."
                  className="h-[59px] w-full px-2 border-[#1e1e1e] border-[1px] border-opacity-25 rounded-[50px] shadow-md" // Updated for border opacity and added shadow
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {searchData && (
                  <div className="absolute bg-[#fff] z-10 shadow w-full left-0 p-3">
                    {searchData.map((i) => {
                      const d = i.name;

                      const Product_name = d.replace(/\s+/g, "-");
                      return (
                        <Link to={`/product/${Product_name}`}>
                          <div className="flex items-center">
                            <img
                              src={i.image_Url[0]?.url}
                              alt=""
                              className="w-[50px] mr-2"
                            />
                            <h5>{i.name}</h5>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>

              <Navbar active={activeHeading} />
              <div className={`${styles.button} ml-4 !rounded-[4px]`}>
                <Link to="/shop-create">
                  <h1 className="text-[#fff] flex items-center">
                    Become Seller <IoIosArrowForward className="ml-1" />
                  </h1>
                </Link>
              </div>
              <br />
              <br />
              <br />

              <div className="flex w-full justify-center">
                {isAuthenticated ? (
                  <div>
                    <Link to="/profile">
                      <img
                        src={`${user.avatar?.url}`}
                        alt=""
                        className="w-[60px] h-[60px] rounded-full border-[3px] border-[#0eae88]"
                      />
                    </Link>
                  </div>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="text-[18px] pr-[10px] text-[#000000b7]"
                    >
                      Login /
                    </Link>
                    <Link
                      to="/sign-up"
                      className="text-[18px] text-[#000000b7]"
                    >
                      Sign up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;