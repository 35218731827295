import React, { useEffect, useState } from "react";
import { Button, Modal, TextField, Box, Input } from "@material-ui/core";
import { AiOutlineDelete, AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { getAllEventsShop, deleteEvent, updateEvent } from "../../redux/actions/event";
import Loader from "../Layout/Loader";
import { toast } from "react-toastify";

const AllEvents = () => {
  const { events, isLoading, success, error } = useSelector((state) => state.events);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    stock: "",
    description: "",
    category: "",
    originalPrice: "",
    images: [],
  });

  useEffect(() => {
    if (seller._id) {
      dispatch(getAllEventsShop(seller._id));
    }
  }, [dispatch, seller._id]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Event updated successfully!");
    }
  }, [error, success]);

  const handleOpen = (event) => {
    if (event && event.id) {
      setSelectedEvent(event);
      setFormData({
        name: event.name,
        price: event.discountPrice,
        stock: event.stock,
        description: event.description,
        category: event.category,
        originalPrice: event.originalPrice,
        images: event.images ? event.images.map(img => img.url) : [],
      });
      setOpen(true);
    } else {
      console.error("Event object does not contain id:", event);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('discountPrice', formData.price);
    formDataToSend.append('stock', formData.stock);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('category', formData.category);

    // Handle images
    formData.images.forEach((image, index) => {
      if (image instanceof File) {
        formDataToSend.append('newImages', image);
      }
    });

    console.log('FormData contents:');
    for (let pair of formDataToSend.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    try {
      await dispatch(updateEvent(selectedEvent.id, formDataToSend));
      handleClose();
      dispatch(getAllEventsShop(seller._id)); // Refresh the events list
    } catch (error) {
      console.error('Error updating event:', error);
      toast.error(error.message || 'Failed to update event');
    }
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteEvent(id));
      dispatch(getAllEventsShop(seller._id)); // Refresh the events list
      toast.success('Event deleted successfully');
    } catch (error) {
      console.error("Error deleting event:", error);
      toast.error('Failed to delete event');
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData(prevState => ({
      ...prevState,
      images: files // Replace existing images with new ones
    }));
  };

  const columns = [
    { field: "id", headerName: "Event Id", minWidth: 150, flex: 0.7 },
    { field: "name", headerName: "Name", minWidth: 180, flex: 1.4 },
    { field: "price", headerName: "Price", minWidth: 100, flex: 0.6 },
    { field: "stock", headerName: "Stock", type: "number", minWidth: 80, flex: 0.5 },
    { field: "sold", headerName: "Sold out", type: "number", minWidth: 130, flex: 0.6 },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/product/${params.id}`}>
          <Button><AiOutlineEye size={20} /></Button>
        </Link>
      ),
    },
    {
      field: "Edit",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleOpen(params.row)}>
          <AiOutlineEdit size={20} />
        </Button>
      ),
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleDelete(params.id)}>
          <AiOutlineDelete size={20} />
        </Button>
      ),
    },
  ];

  const rows = events && events.length > 0 ? events.map((item) => ({
    id: item._id,
    name: item.name,
    price: "US$ " + item.discountPrice,
    stock: item.stock,
    sold: item.sold_out,
  })) : [];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          {events && events.length > 0 ? (
            <DataGrid 
              rows={rows} 
              columns={columns} 
              pageSize={10} 
              disableSelectionOnClick 
              autoHeight 
            />
          ) : (
            <p>No events found.</p>
          )}
          <Modal open={open} onClose={handleClose}>
            <Box
              component="form"
              onSubmit={handleEditSubmit}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                maxHeight: '90vh',
                overflowY: 'auto',
              }}
            >
              <h2>Edit Event</h2>
              <TextField fullWidth margin="normal" label="Name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
              <TextField fullWidth margin="normal" label="Price" value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} />
              <TextField fullWidth margin="normal" label="Stock" value={formData.stock} onChange={(e) => setFormData({ ...formData, stock: e.target.value })} />
              <TextField fullWidth margin="normal" label="Description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
              <TextField fullWidth margin="normal" label="Category" value={formData.category} onChange={(e) => setFormData({ ...formData, category: e.target.value })} />
              <Input type="file" multiple onChange={handleImageChange} inputProps={{ accept: "image/*" }} />
              <div className="w-full flex items-center flex-wrap">
                {formData.images && formData.images.map((image, index) => (
                  <img 
                    src={image instanceof File ? URL.createObjectURL(image) : `https://everydayethiopia-backend.onrender.com${image}`} 
                    key={index} 
                    alt="" 
                    className="h-[120px] w-[120px] object-cover m-2" 
                  />
                ))}
              </div>
              <Button type="submit" color="primary" variant="contained">Update</Button>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AllEvents;
