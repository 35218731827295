import React from 'react'
import { Link } from 'react-router-dom'
import { navItems } from '../../static/data'
import styles from '../../styles/styles'
import UnderlineIcon from '../../Assests/images/active.svg';

const Navbar = ({ active }) => {
  return (
    <div className={`block 800px:${styles.noramlFlex} -ml-[-5]`}> {/* Use a standard negative margin */}
{navItems && navItems.map((i, index) => (
        <div className="relative flex" key={index}>
          <Link
            to={i.url}
            className={`${active === index + 1 ? "text-[1E1E1E]" : "text-black 800px:text-[#1E1E1E]"} pb-[30px] 800px:pb-0 font-[500] px-6 cursor-pointer font-Lato text-[16px] font-[400]`}
          >
            {i.title}
          </Link>
          {active === index + 1 && ( 
            <img
              src={UnderlineIcon} 
              alt="Underline"
              className={`${styles.active_indicator} absolute left-0 right-0 mx-auto`} 
              style={{ height: '15px', width: '100%' }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Navbar