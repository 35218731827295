import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { createCategory, getAllCategories, deleteCategory } from "../../redux/actions/category";
import { toast } from "react-toastify";
import styles from "../../styles/styles";

const Category = () => {
  const dispatch = useDispatch();
  const { categories, isLoading, error } = useSelector((state) => state.category);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [image, setImage] = useState(null);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("subTitle", subTitle);
    if (image) {
      formData.append("image", image);
    }
    dispatch(createCategory(formData));
    setTitle("");
    setSubTitle("");
    setImage(null);
  };

 
  const handleDelete = async (id) => {
    try {
      await dispatch(deleteCategory(id));
      toast.success("Category deleted successfully");
      dispatch(getAllCategories()); // Refresh the category list
    } catch (error) {
      toast.error("Failed to delete category");
    }
  };

  const columns = [
    { field: "id", headerName: "Category ID", minWidth: 150, flex: 0.7 },
    {
      field: "title",
      headerName: "Title",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "subTitle",
      headerName: "Sub Title",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const rows = categories && categories.length > 0
    ? categories.map((item) => ({
        id: item._id,
        title: item.title,
        subTitle: item.subTitle,
      }))
    : [];

  return (
    <div className="w-full mx-8 pt-1 mt-10 bg-white">
      <div className="w-full flex justify-end">
        {/* <div className={`${styles.button} !w-max !h-[45px] px-3 !rounded-[5px] mr-3 mb-3`}>
          <span className="text-white">Add Category</span>
        </div> */}
      </div>
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="mb-2">
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Category
          </label>
          <input
            type="text"
            name="title"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-2">
          <label htmlFor="subTitle" className="block text-sm font-medium text-gray-700">
            Sub Category
          </label>
          <input
            type="text"
            name="subTitle"
            id="subTitle"
            value={subTitle}
            onChange={(e) => setSubTitle(e.target.value)}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-2">
          <label htmlFor="image" className="block text-sm font-medium text-gray-700">
            Image
          </label>
          <input
            type="file"
            name="image"
            id="image"
            onChange={handleImageChange}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <button
          type="submit"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create Category
        </button>
      </form>
      {isLoading ? (
        <p>Loading categories...</p>
      ) : categories && categories.length > 0 ? (
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      ) : (
        <p>No categories found.</p>
      )}
    </div>
  );
};

export default Category;