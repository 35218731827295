import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";


const BestDeals = () => {
  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);
  const { allEvents } = useSelector((state) => state.events); // Get events from state

  const bestDeals = useMemo(() => {
    if (!allProducts || allProducts.length === 0) return [];
    const sortedData = [...allProducts].sort((a, b) => b.sold_out - a.sold_out);
    return sortedData.slice(0, 5); // Top 5 best deals
  }, [allProducts]);

  useEffect(() => {
    setData(bestDeals);
  }, [bestDeals]);

  // Helper function to get the middle index of products
  const middleIndex = Math.floor(data.length / 2);

  return (
    <div>
      <div className={`${styles.section}`}>
        <div className={`${styles.heading} flex items-center justify-center pb-6`}>
          <h1>Best Deals</h1>
        </div>

        {data && data.length > 0 && (
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 border-0">
            {/* Display product cards with event card in the middle */}
            {data.map((product, index) => (
              <React.Fragment key={product.id}>
                {/* Insert the EventCard in the middle of the products */}
                {/* {index === middleIndex && allEvents && allEvents.length > 0 && (
                  <div className="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 flex justify-center">
                    <div className="bg-yellow-400 rounded-lg p-4 text-center custom-event-card"> */}
                      {/* <EventCard data={allEvents[0]} /> */}
                      {/* <div className="mt-4 text-white font-bold">
                        <h2>Popular Surprise Offer</h2>
                        <p>{allEvents[0].eventDuration}</p> Display the event duration */}
                      {/* </div>
                    </div>
                  </div>
                )} */}
                <ProductCard data={product} />
              </React.Fragment>
            ))}
          </div>
        )}

        {/* Handle empty state */}
        {data.length === 0 && (
          <div className="flex justify-center items-center">
            <p>No Best Deals Available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BestDeals;
