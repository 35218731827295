import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { BsCartPlus } from "react-icons/bs";
import styles from "../../styles/styles";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { removeFromWishlist } from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { FaHeart } from "react-icons/fa";

const Wishlist = ({ setOpenWishlist }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  const addToCartHandler = (data) => {
    const newData = { ...data, qty: 1 };
    dispatch(addTocart(newData));
    setOpenWishlist(false);
  };

  return (
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0 right-0 h-full w-[80%] 800px:w-[25%] bg-white flex flex-col overflow-y-scroll justify-between shadow-sm">
        {wishlist && wishlist.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end fixed top-3 right-3">
              <RxCross1
                size={25}
                className="cursor-pointer absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600 transition"
                onClick={() => setOpenWishlist(false)}
              />
            </div>
            <h5>Wishlist Items are empty!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex justify-between items-center p-4">
                <div className="flex items-center">
                  <FaHeart size={35} className="text-[#FAC50C] bg-[#fff] rounded-full p-1" />
                  <h5 className="pl-2 text-[20px] font-[500]">
                    {wishlist && wishlist.length} items
                  </h5>
                </div>
                <RxCross1
                  size={25}
                  className="cursor-pointer bg-[#FAC50C] text-white p-1 rounded-full hover:bg-[#FAC50C] transition"
                  onClick={() => setOpenWishlist(false)}
                />
              </div>
              <div className="w-full border-t">
                {wishlist &&
                  wishlist.map((i, index) => (
                    <WishlistSingle
                      key={index}
                      data={i}
                      removeFromWishlistHandler={removeFromWishlistHandler}
                      addToCartHandler={addToCartHandler}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const WishlistSingle = ({ data, removeFromWishlistHandler, addToCartHandler }) => {
  const [value] = useState(1); // Keep quantity fixed at 1 for wishlist items
  const totalPrice = data.discountPrice * value;
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  return (
    <div className="relative border-b border p-4 flex flex-col shadow-lg rounded-md mb-4">
      <RxCross1
        className="absolute top-2 right-2 cursor-pointer bg-red-500 text-white p-1 rounded-full hover:bg-[#FF0000] transition"
        size={20}
        onClick={() => removeFromWishlistHandler(data)}
      />

      <div className="w-full flex justify-start items-center">
        <img
          src={`https://everydayethiopia-backend.onrender.com${data?.images[0]?.url}`}
          alt="Product Image"
          className="w-[100px] h-[90px] rounded-[10px] object-cover"
        />
        <div className="pl-4 flex-1">
          <h1 className="text-[18px] font-[600]">{data.name}</h1>
          <h4 className="font-[400] text-[15px] text-[#00000082]">
            {data.discountPrice} Birr X {value}
          </h4>
          <h4 className="font-[600] text-[17px] text-[#1e1e1e] font-Roboto">
            {totalPrice} Birr
          </h4>
        </div>
        <div>
          <BsCartPlus
            size={30}
            className="cursor-pointer bg-[#FAC50C] text-white p-1 rounded-full hover:bg-[#FAC50C] transition"
            title="Add to cart"
            onClick={() => addToCartHandler(data)}
          />
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
