import { React, useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { FaEnvelope } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import Logo from "../../Assests/images/logo.png";

const ShopLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .post(
        `${server}/shop/login-shop`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Login Success!");
        navigate("/dashboard");
        window.location.reload(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-white-100">
      <img src={Logo} alt="Logo" className="w-40" />
      <div className="bg-white rounded-[70px] shadow-lg p-10 w-[35%] border border-[#1E1E1E] mt-4"> {/* Added mt-4 for margin-top */}

      <h2 className="text-center text-[32px] font-medium text-[1E1E1E]-900 font-Lato">
        Login to your shop
      </h2>

      <form className="space-y-6 mt-4" onSubmit={handleSubmit}>
        <div className="relative">
          <FaEnvelope
            className="absolute left-3 top-3 text-[#FAC50C]"
            size={15}
          />
          <input
            type="email"
            name="email"
            value={email}
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            className="pl-12 w-full ont-Lato text-[#1E1E1E] rounded-[20px] border-[1E1E1E]-300 shadow-sm focus:ring-[#FAC50C] focus:border-[#FAC50C] sm:text-sm px-3 py-2 border border-[1E1E1E]"
            placeholder="Email Address"
            required
          />
        </div>
        <div className="relative">
          <RiLockPasswordFill
            className="absolute left-3 top-3  text-[#FAC50C]"
            size={15}
          />
          <input
            type={visible ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="pl-12 w-full font-Lato text-[#1E1E1E] rounded-[20px] border-[1E1E1E]-300 shadow-sm focus:ring-[#FAC50C] focus:border-[#FAC50C] sm:text-sm px-3 py-2 border border-[1E1E1E]"
            placeholder="Password"
            required
          />
          {visible ? (
            <AiOutlineEye
              className="absolute right-3 top-3 cursor-pointer text-gray-400"
              size={15}
              onClick={() => setVisible(false)}
            />
          ) : (
            <AiOutlineEyeInvisible
              className="absolute right-3 top-3 cursor-pointer text-gray-400"
              size={15}
              onClick={() => setVisible(true)}
            />
          )}
        </div>
        <div className={`${styles.noramlFlex} justify-between`}>
          <div className={`${styles.noramlFlex}`}>
            <input
              type="checkbox"
              name="remember-me"
              id="remember-me"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label
              htmlFor="remember-me"
              className="ml-2 block text-sm text-gray-900"
            >
              Remember me
            </label>
          </div>
          <div className="text-sm">
            <a href="forgot-password" className="font-medium text-[#0C2DD2]">
              Forgot your password?
            </a>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className={`${styles.button} py-1 px-4`} // Adjusted padding to decrease height
          >
            Sign In
          </button>
        </div>
        <div className={`${styles.noramlFlex} w-full `}>
          <h4>Don't have any account?</h4>
          <Link to="/shop-create" className="text-[#0C2DD2] font-medium">
            Sign Up
          </Link>
        </div>
      </form>
    </div>
    </div>
  );
};

export default ShopLogin;
