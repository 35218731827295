import React from 'react'
import DashboardHeader from "../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../components/Shop/Layout/DashboardSideBar";
import Category from "../components/Category/Category";

const CategoryPage = () => {
  return (
    <div>
      <DashboardHeader />
      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <DashboardSideBar active={12} />
        </div>
        <div className="w-full justify-center flex overflow-y-scroll h-[90vh]">
          <Category />
        </div>
      </div>
    </div>
  )
}

export default CategoryPage;