import React from "react";
import Footer from "../components/Layout/Footer";
import { useNavigate } from "react-router-dom";
import Header from "../components/Layout/Header";
import Lottie from "react-lottie";
import animationData from "../Assests/animations/107043-success.json";
import styles from "../styles/styles"

const OrderSuccessPage = () => {
  return (
    <div>
      <Header />
      <Success />
      <Footer />
    </div>
  );
};

const Success = () => {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleHomeClick = () => {
    navigate("/"); // Redirects to home page
  };

  return (
    <div className="flex flex-col items-center  min-h-screen color-[#FAC50C]">
      <Lottie options={defaultOptions} width={300} height={300} />
      <h5 className="text-center mb-4 text-[25px] text-[#000000a1]">
         Order Successful!
      </h5>
      <button
          onClick={handleHomeClick}
          className={`${styles.button}  mt-1 bg-yellow-500 text-black px-2 py-2 rounded-full font-semibold hover:bg-yellow-600 transition`}
        >
          Go back home
        </button>
    
      
    </div>
  );
};

export default OrderSuccessPage;
