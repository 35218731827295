const styles = {
    custom_container: "w-11/12 hidden sm:block",
    heading: 'text-[47px] text-center text-[1E1E1E] md:text-start font-medium font-Lato pb-[20px]',
    section:'w-11/12 mx-auto',
    productTitle: 'text-[25px] font-[600] font-Roboto text-[#333]',
    productDiscountPrice:"font-bold text-[18px] text-[#333] font-Roboto",
    price:"font-[500] text-[16px] text-[#d55b45] pl-3 mt-[-4px] line-through",
    // shop_name:"pt-3 text-[15px] text-blue-400 pb-3",
    shop_name:"pt-1 text-[15px] text-[#FAC50C] pb-1",
    active_indicator:"absolute bottom-[-27%] left-0 h-[3px] w-full ",
    button: 'w-[160px] bg-[#FAC50C] h-[53px] my-3 flex items-center justify-center rounded-[50px] cursor-pointer font-Lato text-[16px] font-[600]',
    cart_button: "px-[20px] h-[38px] rounded-[20px] bg-[#f63b60] flex items-center justify-center cursor-pointer",
    cart_button_text:"text-[#fff] text-[16px] font-[600]",
    input: "border border-gray-300 rounded-full p-2 w-full ",
    activeStatus:"w-[10px] h-[10px] rounded-full absolute top-0 right-1 bg-[#40d132]",
    noramlFlex:"flex items-center "
  };
  
  export default styles;
  
