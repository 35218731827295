import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styles from "../../../styles/styles";
import image1 from "../../../Assests/images/shoes.jpg";
import image2 from "../../../Assests/images/cloth.jpg";
import image from "../../../Assests/images/header.png";
import pattern from "../../../Assests/images/pattern.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PreviousArrow = ({ onClick }) => (
  <button
    className="absolute top-[50%] left-[15px] z-10 transform -translate-y-[50%] bg-white text-black p-3 rounded-full shadow-md cursor-pointer"
    onClick={onClick}
  >
    &#10094;
  </button>
);

const NextArrow = ({ onClick }) => (
  <button
    className="absolute top-[50%] right-[15px] z-10 transform -translate-y-[50%] bg-white text-black p-3 rounded-full shadow-md cursor-pointer"
    onClick={onClick}
  >
    &#10095;
  </button>
);

const Hero = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <PreviousArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div className="relative w-full">
      {/* Slider with custom height */}
      <Slider {...settings}>
        <div>
          <img
            src={image1}
            alt="Shoes"
            className="w-full h-[50vh] 800px:h-[65vh] object-cover"
          />
        </div>
        <div>
          <img
            src={image2}
            alt="Cloth"
            className="w-full h-[50vh] 800px:h-[65vh] object-cover"
          />
        </div>
        <div>
          <img
            src={image}
            alt="Header"
            className="w-full h-[50vh] 800px:h-[65vh] object-cover"
          />
        </div>
      </Slider>

      {/* Static text overlay */}
      <div className="absolute inset-0 flex items-center justify-start">
        <div
          className={`${styles.section} w-[90%] 800px:w-[60%]`}
          style={{ marginLeft: "55px" }}
        >
          <h1
            className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#fff] font-[600] w-[70%]`}
          >
            Authentic <span className="herospan">Ethiopian</span> products,
            crafted for <span className="herospan">everyday</span> living.
          </h1>

          <Link to="/products" className="inline-block">
            <div className={`${styles.button} mt-5`}>
              <span className="text-[#1E1E1E] font-[Lato] text-[16px] font-[600]">
                Shop Now
              </span>
            </div>
          </Link>
        </div>
      </div>

      {/* Pattern background */}
      <div
        className="w-full h-[50px] bg-no-repeat"
        style={{
          backgroundImage: `url(${pattern})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </div>
  );
};

export default Hero;
