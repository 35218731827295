import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { brandingData } from "../../../static/data";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/styles";
import { getAllCategories } from "../../../redux/actions/category";

const Categories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Safeguard against undefined categories
  const { categories = [], loading } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const handleSubmit = (categoryId) => {
    navigate(`/products?category=${categoryId}`);
  };

  // Function to construct the full image URL
  const getImageUrl = (imageUrl) => {
    return `https://everydayethiopia-backend.onrender.com${imageUrl}`;
  };

  // Filter categories based on their titles
  const clothingAndAccessories = categories.filter((category) =>
    ["Clothing", "Scarves", "Jewelries", "Bags"].includes(category.title)
  );

  const homeAndDecor = categories.filter((category) =>
    ["Rugs", "Pottery", "Frames", "Candles"].includes(category.title)
  );

  const remainingCategories = categories.filter(
    (category) =>
      ![
        "Clothing",
        "Scarves",
        "Jewelries",
        "Bags",
        "Rugs",
        "Pottery",
        "Frames",
        "Candles",
      ].includes(category.title)
  );

  return (
    <>
      {/* Categories Section */}
      <div
        className={`${styles.section} hidden sm:block p-8 rounded-lg mb-12`}
        id="categories"
      >
        <div className="flex justify-between">
          {/* Clothing & Accessories Card */}
          <div
            className="w-full max-w-[420px] lg:max-w-[420px] mr-4 rounded-[30px] bg-white border border-opacity-25 border-[#1E1E1E] p-4"
            style={{ boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.15)" }}
          >
            <h2 className="text-xl sm:text-2xl font-Lato font-[500] text-[22px] mb-4 ml-5">
              Clothing & Accessories
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {loading ? (
                <p>Loading categories...</p>
              ) : (
                <>
                  {/* First column with one category in portrait size */}
                  {clothingAndAccessories.length > 0 && (
                    <div className="flex flex-col items-center">
                      <div
                        className="relative w-[150px] sm:w-[170px] md:w-[190px] h-[250px] sm:h-[300px] md:h-[350px] flex flex-col items-center justify-center cursor-pointer -mr-4 sm:-mr-6 md:-mr-8"
                        onClick={() =>
                          handleSubmit(clothingAndAccessories[0]._id)
                        }
                      >
                        <img
                          src={
                            clothingAndAccessories[0].image_Url
                              ? getImageUrl(clothingAndAccessories[0].image_Url)
                              : "/placeholder-image.jpg"
                          }
                          className="w-full h-full object-cover rounded-lg"
                          alt={
                            clothingAndAccessories[0].title || "Category Image"
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/placeholder-image.jpg";
                          }}
                        />
                        {/* Overlay with background color and opacity */}
                        <div className="absolute inset-0 bg-[#1e1e1e] opacity-0 hover:opacity-70 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                          <button
                            className="bg-[#FAC50C] text-black font-Lato font-semibold py-2 px-2 sm:py-2 sm:px-4 rounded-full shadow-md z-10 text-sm sm:text-base"
                            onClick={() =>
                              handleSubmit(clothingAndAccessories[0]._id)
                            }
                          >
                            {clothingAndAccessories[0].title}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Second column with three categories */}
                  <div className="grid grid-cols-1 gap-4">
                    {clothingAndAccessories.slice(1, 4).map((category) => (
                      <div
                        key={category._id}
                        className="relative w-[120px] h-[100px] sm:w-[140px] md:w-[160px] flex flex-col items-center cursor-pointer ml-4"
                        px
                        onClick={() => handleSubmit(category._id)}
                      >
                        <img
                          src={
                            category.image_Url
                              ? getImageUrl(category.image_Url)
                              : "/placeholder-image.jpg"
                          }
                          className="w-full h-full object-cover mb-2 rounded-lg"
                          alt={category.title || "Category Image"}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/placeholder-image.jpg";
                          }}
                        />
                        {/* Overlay button - Updated to match the first column */}
                        <div className="absolute inset-0 bg-[#1e1e1e] opacity-0 hover:opacity-70 transition-opacity duration-300 rounded-lg flex items-center justify-center h-[100px]">
                          <button
                            className="bg-[#FAC50C] text-black font-Lato font-semibold py-1 px-2 sm:py-2 sm:px-4 rounded-full shadow-md z-10 text-sm sm:text-base"
                            onClick={() => handleSubmit(category._id)}
                          >
                            {category.title}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Remaining Categories Section */}
          <div className="mt-6">
            <div className="grid grid-cols-1 gap-4">
              {loading ? (
                <p>Loading categories...</p>
              ) : (
                remainingCategories.map((category) => (
                  <div
                    className="relative flex flex-col items-center justify-center cursor-pointer overflow-hidden rounded-[20px] w-[280px] h-[180px] bg-white shadow-md hover:shadow-lg transition-shadow duration-300 mr-3"
                    key={category._id}
                    onClick={() => handleSubmit(category._id)}
                  >
                    <img
                      src={
                        category.image_Url
                          ? getImageUrl(category.image_Url)
                          : "/placeholder-image.jpg"
                      }
                      className="w-full h-full object-cover rounded-lg"
                      alt={category.title || "Category Image"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/placeholder-image.jpg";
                      }}
                    />
                    {/* Overlay with category name on hover */}
                    <div className="absolute inset-0 bg-[#1e1e1e] opacity-0 hover:opacity-70 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                      <button
                        className="bg-[#FAC50C] text-black font-Lato font-semibold py-1 px-2 sm:py-2 sm:px-4 rounded-full shadow-md z-10 text-sm sm:text-base"
                        onClick={() => handleSubmit(category._id)}
                      >
                        {category.title}
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Home & Décor Card */}
          <div
            className="w-full max-w-[420px] lg:max-w-[420px] mr-4 rounded-[30px] bg-white border border-opacity-25 border-[#1E1E1E] p-4"
            style={{ boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.15)" }}
          >
            <h2 className="text-xl sm:text-2xl font-Lato font-[500] text-[22px] mb-4 ml-5">
              Home & Décor
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {loading ? (
                <p>Loading categories...</p>
              ) : (
                <>
                  {/* First column with one category in portrait size */}
                  {homeAndDecor.length > 0 && (
                    <div className="flex flex-col items-center">
                      <div
                        className="relative w-[150px] sm:w-[170px] md:w-[190px] h-[250px] sm:h-[300px] md:h-[350px] flex flex-col items-center justify-center cursor-pointer -mr-4 sm:-mr-6 md:-mr-8"
                        onClick={() => handleSubmit(homeAndDecor[0]._id)}
                      >
                        <img
                          src={
                            homeAndDecor[0].image_Url
                              ? getImageUrl(homeAndDecor[0].image_Url)
                              : "/placeholder-image.jpg"
                          }
                          className="w-full h-full object-cover rounded-lg" // Updated with object-cover
                          style={{ objectFit: "cover" }} // Ensures image fits the container without distortion
                          alt={homeAndDecor[0].title || "Category Image"}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/placeholder-image.jpg";
                          }}
                        />
                        {/* Overlay with background color and opacity */}
                        <div className="absolute inset-0 bg-[#1e1e1e] opacity-0 hover:opacity-70 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                          <button
                            className="bg-[#FAC50C] text-black font-Lato font-semibold py-2 px-2 sm:py-2 sm:px-4 rounded-full shadow-md z-10 text-sm sm:text-base"
                            onClick={() => handleSubmit(homeAndDecor[0]._id)}
                          >
                            {homeAndDecor[0].title}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Second column with three categories */}
                  <div className="grid grid-cols-1 gap-4">
                    {homeAndDecor.slice(1, 4).map((category) => (
                      <div
                        key={category._id}
                        className="relative w-[120px] h-[100px] sm:w-[140px] md:w-[160px] flex flex-col items-center cursor-pointer ml-4"
                        onClick={() => handleSubmit(category._id)}
                      >
                        <img
                          src={
                            category.image_Url
                              ? getImageUrl(category.image_Url)
                              : "/placeholder-image.jpg"
                          }
                          className="w-full h-full object-cover mb-2 rounded-lg" // Ensures image fits container
                          alt={category.title || "Category Image"}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/placeholder-image.jpg";
                          }}
                        />
                        {/* Overlay button - Updated to match the first column */}
                        <div className="absolute inset-0 bg-[#1e1e1e] opacity-0 hover:opacity-70 transition-opacity duration-300 rounded-lg flex items-center justify-center h-[100px]">
                          <button
                            className="bg-[#FAC50C] text-black font-Lato font-semibold py-1 px-2 sm:py-2 sm:px-4 rounded-full shadow-md z-10 text-sm sm:text-base"
                            onClick={() => handleSubmit(category._id)}
                          >
                            {category.title}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.section} hidden sm:block`}>
      <div className={`my-12 flex justify-between p-6`}>
  {brandingData &&
    brandingData.map((i, index) => (
      <div className="flex flex-col items-center mx-[10px]" key={index}>
        {/* Circular container for icon and text */}
        <div className="flex flex-col items-center justify-center rounded-full w-[190px] h-[190px] bg-white shadow-md overflow-hidden p-4 border border-[#FAC50C]"  style={{ boxShadow: "2px 4px 22px rgba(0, 0, 0, 0.15)" }}>
          <div className="flex items-center justify-center w-16 h-16">
            {i.icon}
          </div>
          <h3 className="font-Lato font-bold text-[16px] text-[#1E1E1E] text-center">{i.title}</h3>            
             <p className="text-xs md:text-sm">{i.Description}</p>
        </div>
      </div>
    ))}
</div>

      </div>
    </>
  );
};

export default Categories;
