import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { IoBagHandleOutline } from "react-icons/io5";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart, removeFromCart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import { MdOutlineShoppingCart } from "react-icons/md";
import { FiShoppingCart } from "react-icons/fi";
import { FaShoppingBag } from "react-icons/fa";

const Cart = ({ setOpenCart }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  return (
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0 right-0 h-full w-[80%] 800px:w-[25%] bg-white flex flex-col overflow-y-scroll justify-between shadow-sm">
        {cart && cart.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end fixed top-3 right-3">
              <RxCross1
                size={25}
                className="cursor-pointer absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600 transition"
                onClick={() => setOpenCart(false)}
              />
            </div>
            <h5>Cart Items is empty!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pt-5 pr-5 "></div>
              {/* Item length */}
           <div className="flex justify-between items-center p-4">
  {/* Left side: Bag Icon and Item Count */}
  <div className="flex items-center">
    {/* <IoBagHandleOutline size={25} className="text-[#fff] bg-[#FAC50C] rounded-full" /> 
    <MdOutlineShoppingCart size={25} className="text-[#fff] bg-[#FAC50C] rounded-full p-1" />
    <FiShoppingCart size={25} className="text-[#fff] bg-[#FAC50C] rounded-full p-1" /> */}
    <FaShoppingBag size={35} className="text-[#FAC50C] bg-[#fff] rounded-full p-1" />
    <h5 className="pl-2 text-[20px] font-[500]">
      {cart && cart.length} items
    </h5>
  </div>

  {/* Right side: RxCross1 (Close button) */}
  <RxCross1
    size={25}
    className="cursor-pointer bg-[#FAC50C] text-white p-1 rounded-full hover:bg-[#FAC50C] transition"
    onClick={() => setOpenCart(false)}
  />
</div>


              {/* cart Single Items */}
              <br />
              <div >
                {cart &&
                  cart.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      quantityChangeHandler={quantityChangeHandler}
                      removeFromCartHandler={removeFromCartHandler}
                    />
                  ))}
              </div>
            </div>
            <div className="px-3 mb-14">
  <div className="flex boared justify-between items-center">
    
    {/* Cart Detail Button */}
    <Link to="/cart-details">
      <div className={`${styles.button} w-[110px] text-[#1e1e1e] bg-[#FFFFFF] border border-[#FAC50C] `}>
        <h1>Cart Detail</h1>
      </div>
    </Link>
    {/* Checkout Button */}
    <Link to="/checkout">
      <div className={`${styles.button} w-[110px] text-[#1E1E1E] ml-2 bg-[#FAC50C]`}>
        <h1>Checkout</h1>
      </div>
    </Link>

    
   
  </div>
</div>

          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
  const [value, setValue] = useState(data.qty);
  const totalPrice = data.discountPrice * value;

  const increment = (data) => {
    if (data.stock < value) {
      toast.error("Product stock limited!");
    } else {
      setValue(value + 1);
      const updateCartData = { ...data, qty: value + 1 };
      quantityChangeHandler(updateCartData);
    }
  };

  const decrement = (data) => {
    setValue(value === 1 ? 1 : value - 1);
    const updateCartData = { ...data, qty: value === 1 ? 1 : value - 1 };
    quantityChangeHandler(updateCartData);
  };

  return (
   <div className="relative border-b border p-4 flex flex-col shadow-lg rounded-md mb-4">
  {/* Close (Remove Item) Button */}
  <RxCross1
    className="absolute top-2 right-2 cursor-pointer bg-red-500 text-white p-1 rounded-full hover:bg-[#FF0000] transition"
    size={25}
    onClick={() => removeFromCartHandler(data)}
  />

  <div className="w-full flex justify-start items-center">
    {/* Image Section */}
    <img
      src={`https://everydayethiopia-backend.onrender.com${data?.images[0]?.url}`}
      alt="Product Image"
      className="w-[100px] h-[90px] rounded-[10px] object-cover"
    />

    {/* Product Info Section */}
    <div className="pl-4 flex-1">
      <h1 className="text-[18px] font-[600]">{data.name}</h1>
      <h4 className="font-[400] text-[15px] text-[#00000082]">
        {data.discountPrice} Birr X {value}
      </h4>
      <h4 className="font-[600] text-[17px] text-[#1e1e1e] font-Roboto">
        {totalPrice} Birr
      </h4>
    </div>
  </div>

  {/* Quantity Controls */}
  <div className="flex items-center justify-center mt-2">
  <div
    className="border border-[#FAC50C] text-[#FAC50C] font-bold rounded-full w-5 h-5 flex items-center justify-center shadow-lg hover:opacity-75 transition duration-300 ease-in-out cursor-pointer"
    onClick={() => decrement(data)}
  >
    <HiOutlineMinus size={10} color="#1E1E1E" />
  </div>
  <span className="px-4 text-[16px]">{value}</span>
  <div
    className="border border-[#FAC50C] text-[#FAC50C] font-bold rounded-full w-5 h-5 flex items-center justify-center shadow-lg hover:opacity-75 transition duration-300 ease-in-out cursor-pointer"
    onClick={() => increment(data)}
  >
    <HiPlus size={10} color="#1E1E1E" />
  </div>
</div>

</div>

  );
};

export default Cart;
