import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";
import Logo from "../../Assests/images/logo.png";
import { FaEnvelope } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaStore } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";


const ShopCreate = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState();
  const [avatar, setAvatar] = useState(null);
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("phoneNumber", phoneNumber);
    formData.append("address", address);
    formData.append("zipCode", zipCode);
    if (avatar) {
      formData.append("avatar", avatar);
    }

    console.log("FormData contents:");
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    try {
      const res = await axios.post(`${server}/shop/create-shop`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success(res.data.message);
      setName("");
      setEmail("");
      setPassword("");
      setAvatar(null);
      setZipCode("");
      setAddress("");
      setPhoneNumber("");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const [selectedForm, setSelectedForm] = useState("seller"); // Initialize selectedForm state
  const navigate = useNavigate(); 

  const handleToggle = (formType) => {
    // Set the selected form type
    setSelectedForm(formType);
    
    // Navigate to the ShopCreate component
    navigate("/sign-up"); // Adjust the path as necessary
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-white-100">
    <img src={Logo} alt="Logo" className="mt-64 w-40" /> 
    {/* Toggle Buttons */}
    <div className="flex space-x-8 mt-4">
      <div
        className={`cursor-pointer flex text-[1E1E1E] font-Lato text-[12px]  font-bold items-center justify-center w-16 h-16 rounded-full border-2 
          ${selectedForm === "shopper" ? "bg-yellow-500 text-white" : "bg-white text-gray-700 border-yellow-500"}`}
        onClick={() => handleToggle("shopper")}
      >
        Shopper
      </div>

      <div
        className={`cursor-pointer flex  text-[1E1E1E] font-Lato text-[12px]  items-center justify-center w-16 h-16 rounded-full border-2 
          ${selectedForm === "seller" ? "bg-yellow-500 text-white" : "bg-white text-gray-700 border-yellow-500"}`}
        onClick={() => handleToggle("seller")}
      >
        Seller
      </div>
    </div>
    <div className="bg-white rounded-[70px] shadow-lg p-10 w-[35%] border border-[#1E1E1E] mt-4"> {/* Added mt-4 for margin-top */}
        <h2 className="text-center text-[32px] font-medium text-[1E1E1E]-900 font-Lato">
          Register As Seller
        </h2>
     
  
          <form className="space-y-6 mt-4" onSubmit={handleSubmit}>
          <div className="relative">
            <FaStore className="absolute left-3 top-3 text-[#FAC50C]" size={15} />
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="pl-12 w-full ont-Lato text-[#1E1E1E] rounded-[20px] border-[1E1E1E]-300 shadow-sm focus:ring-[#FAC50C] focus:border-[#FAC50C] sm:text-sm px-3 py-2 border border-[1E1E1E]"
              placeholder="Shop Name"
              required
            />
          </div>
            

          <div className="relative">
            <FaPhoneAlt className="absolute left-3 top-3 text-[#FAC50C]" size={15} />
            <input
              type="number"
              name="phonenumber"
              value={phoneNumber}
              onChange={(e) => setEmail(e.target.value)}
              className="pl-12 w-full ont-Lato text-[#1E1E1E] rounded-[20px] border-[1E1E1E]-300 shadow-sm focus:ring-[#FAC50C] focus:border-[#FAC50C] sm:text-sm px-3 py-2 border border-[1E1E1E]"
              placeholder="Phone Number"
              required
            />
          </div>
          <div className="relative">
            <FaEnvelope className="absolute left-3 top-3 text-[#FAC50C]" size={15} />
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="pl-12 w-full ont-Lato text-[#1E1E1E] rounded-[20px] border-[1E1E1E]-300 shadow-sm focus:ring-[#FAC50C] focus:border-[#FAC50C] sm:text-sm px-3 py-2 border border-[1E1E1E]"
              placeholder="Email Address"
              required
            />
          </div>
          
          <div className="relative">
            <FaAddressCard className="absolute left-3 top-3 text-[#FAC50C]" size={15} />
            <input
              type="text"
              name="address"
              value={address}
              onChange={(e) => setEmail(e.target.value)}
              className="pl-12 w-full ont-Lato text-[#1E1E1E] rounded-[20px] border-[1E1E1E]-300 shadow-sm focus:ring-[#FAC50C] focus:border-[#FAC50C] sm:text-sm px-3 py-2 border border-[1E1E1E]"
              placeholder="Address"
              required
            />
          </div>

            <div className="relative">
            <FaEnvelope className="absolute left-3 top-3 text-[#FAC50C]" size={15} />
            <input
              type="number"
              name="zipcode"
              value={zipCode}
              onChange={(e) => setEmail(e.target.value)}
              className="pl-12 w-full ont-Lato text-[#1E1E1E] rounded-[20px] border-[1E1E1E]-300 shadow-sm focus:ring-[#FAC50C] focus:border-[#FAC50C] sm:text-sm px-3 py-2 border border-[1E1E1E]"
              placeholder="Zip Code"
              required
            />
          </div>

          <div className="relative">
            <RiLockPasswordFill  className="absolute left-3 top-3  text-[#FAC50C]" size={15}  />
            <input
              type={visible ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="pl-12 w-full font-Lato text-[#1E1E1E] rounded-[20px] border-[1E1E1E]-300 shadow-sm focus:ring-[#FAC50C] focus:border-[#FAC50C] sm:text-sm px-3 py-2 border border-[1E1E1E]"
              placeholder="Password"
              required
            />
            {visible ? (
              <AiOutlineEye
                className="absolute right-3 top-3 cursor-pointer text-gray-400"
                size={15}
                onClick={() => setVisible(false)}
              />
            ) : (
              <AiOutlineEyeInvisible
                className="absolute right-3 top-3 cursor-pointer text-gray-400"
                size={15}
                onClick={() => setVisible(true)}
              />
            )}
          </div>

          <div className="relative">
            <div className="flex items-center">
              <RxAvatar className="text-[#FAC50C]" size={40} />
              <label
                htmlFor="file-input"
                className="ml-5 border border-[#FAC50C] text-[#1E1E1E] px-4 py-2 rounded-[20px] shadow-sm text-sm font-medium cursor-pointer hover:bg-[#FAC50C]"
              >
                Upload Avatar
                <input
                  id="file-input"
                  name="avatar"
                  type="file"
                  className="sr-only"
                  onChange={handleFileInputChange}
                  accept="image/*"
                />
              </label>
            </div>
          </div>

          <div className="flex justify-center">
          <button
              type="submit"
              className={`${styles.button} py-1 px-4`} // Adjusted padding to decrease height
            >
              Sign up
            </button>
          </div>
            <div className={`${styles.noramlFlex} w-full`}>
              <h4>Already have an account?</h4>
              <Link to="/shop-login" className="text-[#0C2DD2] font-medium">
                Sign in
              </Link>
            </div>
          </form>
        </div>
      </div>
  
  );
};

export default ShopCreate;
