import React from "react";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart, removeFromCart } from "../../redux/actions/cart";
import styles from "../../styles/styles";

const CartDetails = () => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  return (
    <div className="w-full min-h-screen shadow-md p-8">
      <h1 className="text-4xl font-bold text-center mb-6">Cart</h1>
      <div className="w-full overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead>
            <tr className=" border-b">
              <th className="text-left p-4">Product</th>
              <th className="text-center p-4">Quantity</th>
              <th className="text-center p-4">Price</th>
              <th className="text-center p-4">Subtotal</th>
              <th className="text-center p-4">Remove</th>
            </tr>
          </thead>
          <tbody>
            {cart.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="flex items-center p-4">
                  <img
                    src={`https://everydayethiopia-backend.onrender.com${item?.images[0]?.url}`}
                    alt="Product"
                    className="w-20 h-20 object-cover rounded-md"
                  />
                  <div className="ml-4">
                    <p className="font-semibold">{item.name}</p>
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex justify-center items-center">
                    <button
                      className="border border-[#FAC50C] rounded-full w-5 h-5 flex justify-center items-center text-[#FAC50C] hover:bg-[#FAC50C] hover:text-white transition duration-300"
                      onClick={() => quantityChangeHandler({ ...item, qty: item.qty - 1 })}
                    >
                      <HiOutlineMinus size={10} />
                    </button>
                    <span className="px-4">{item.qty}</span>
                    <button
                      className="border border-[#FAC50C] rounded-full w-5 h-5 flex justify-center items-center text-[#FAC50C] hover:bg-[#FAC50C] hover:text-white transition duration-300"
                      onClick={() => quantityChangeHandler({ ...item, qty: item.qty + 1 })}
                    >
                      <HiPlus size={10} />
                    </button>
                  </div>
                </td>
                <td className="text-center">{item.discountPrice} Birr</td>
                <td className="text-center">{item.qty * item.discountPrice} Birr</td>
                <td className="text-center">
                  <button
                    className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition duration-300"
                    onClick={() => removeFromCartHandler(item)}
                  >
                    <RxCross1 size={10} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between mt-6">
        <Link to="/products">
          <button className={`${styles.button} text-[#1e1e1e] bg-[#FFFFFF] border border-[#FAC50C] `}>
            Continue Shopping
          </button>
        </Link>
        <div className="text-right">
          <p className="text-xl py-2 px-6  font-semibold">Total: {totalPrice} Birr</p>
          <Link to="/checkout">
            <button className={`${styles.button} bg-[#FAC50C] text-[#1e1e1e] py-2 px-6 rounded-full mt-4 hover:bg-[#e6b700] transition duration-300`}>
              Checkout
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CartDetails;
