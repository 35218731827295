import React, { useState } from "react";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-toastify";
import styles from "../styles/styles";
import { FaEnvelope } from "react-icons/fa";
import Logo from "../Assests/images/logo.png";


const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await axios.post(`${server}/user/password/forgot`, { email });
      toast.success(response.data.message);
      setEmail("");
    } catch (error) {
      console.error("Error details:", error);
      console.error("Request URL:", `${server}/user/password/forgot`);
      console.error("Error response:", error.response);
  
      const errorMessage = error.response?.data?.message || "Something went wrong";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-white-100">
    <img src={Logo} alt="Logo" className="w-40" />
    <div className="bg-white rounded-[70px] shadow-lg p-10 w-[35%] border border-[#1E1E1E] mt-4"> {/* Added mt-4 for margin-top */}

    <h2 className="text-center text-[32px] font-medium text-[1E1E1E]-900 font-Lato">
 Forgot Password
    </h2>
          <form className="space-y-6 mt-4" onSubmit={handleForgotPassword}>
          <div className="relative">
          <FaEnvelope
            className="absolute left-3 top-3 text-[#FAC50C]"
            size={15}
          />
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="pl-12 w-full ont-Lato text-[#1E1E1E] rounded-[20px] border-[1E1E1E]-300 shadow-sm focus:ring-[#FAC50C] focus:border-[#FAC50C] sm:text-sm px-3 py-2 border border-[1E1E1E]"
            placeholder="Email Address"
            required
          />
        </div>
        <div className="flex justify-center">
              <button
                type="submit"
                disabled={loading}
                className={`${styles.button} py-1 px-4`} // Adjusted padding to decrease height
                >
                {loading ? "Sending..." : "Send Reset Link"}
              </button>
            </div>
          </form>
        </div>
      </div>
  
  );
};

export default ForgotPasswordPage;
