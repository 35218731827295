import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";
import Logo from "../../Assests/images/logo-white.png";
import pattern from "../../Assests/images/pattern2.svg";

const Footer = () => {
  return (
    <div className="bg-[#1E1E1E] text-white font-Lato">

      <div className="grid grid-cols-1 sm:gird-cols-3 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16 sm:text-center ml-10">
        <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
          {/* <img
            src="https://shopo.quomodothemes.website/assets/images/logo.svg"
            alt=""
            style={{ filter: "brightness(0) invert(1)" }}
          /> */}
      <img src={Logo} alt="Everyday Ethiopian Logo" className="w-32 h-auto" /> {/* Adjust width as needed */}
      <br />
          
          
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-Lato font-semibold">Company</h1>
          {footerProductLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="text-white-400 hover:text-[#FAC50C] duration-300
                   text-sm cursor-pointer leading-6"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-Lato font-semibold">Shop</h1>
          {footercompanyLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="text-white-400 hover:text-[#FAC50C] duration-300
                   text-sm cursor-pointer leading-6"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-Lato font-semibold">Support</h1>
          {footerSupportLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="text-white-400 hover:text-[#FAC50C] duration-300
                   text-sm cursor-pointer leading-6"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <hr className="border-t border-white mb-4 w-[90%] mx-auto" /> {/* Horizontal line */}
      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
         text-center pt-2 text-white-400 text-sm pb-8"
      >
        <span>© 2024 perspective. All rights reserved.</span>
        <span>Terms · Privacy Policy</span>
        <span className="ml-[50%]">
        <div className="flex items-center ">

            <AiFillFacebook size={25} className="cursor-pointer" />
           
            <AiFillInstagram
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
            <AiFillYoutube
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
          </div>
          </span>
        
        {/* <div className="sm:block flex items-center justify-center w-full">
          <img
            src="http://localhost:8000/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
            alt=""
          />
        </div> */}
       
      </div>
      <div
        className="w-full h-[50px] bg-no-repeat"
        style={{
          backgroundImage: `url(${pattern})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </div>
  );
};

export default Footer;
